<template>

  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <h3>Finalidades de Locações</h3>
    </div>
  </div>

  <div class="p-grid">

    <div class="p-col-12 p-md-8">
      <DataTable sortMode="single" :value="finalidades" :loading="loading">
        <Column field="nome" header="Nome"></Column>
        <Column class="col-actions">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-warning p-mr-2"
              @click="$router.push({ path: `/locacao-finalidade/${slotProps.data.id}` })"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="excluir($event, slotProps.data)"
            />
          </template>
        </Column>
        <template #empty>
            Nenhuma Finalidade de Locação encontrada.
        </template>
      </DataTable>
    </div>

    <div class="p-col-12 p-md-4">
      <div class="card p-fluid">
        <h5 v-if="cadastro">Nova Finalidade de Locação</h5>
        <h5 v-else>Alteração de Finalidade de Locação</h5>

        <div class="p-grid">
          <div class="p-field p-md-12">
            <label for="nome">Nome</label>
            <InputText id="nome" type="text" v-model="nome" />
          </div>

          <div class="p-field p-md-12">
            <div class="p-field-checkbox">
              <Checkbox id="outro" v-model="possui_outro" :binary="true" />
              <label for="outro">Solicita informações PJ</label>
            </div>
          </div>

          <div class="p-md-12" v-if="cadastro">
            <Button
              label="Gravar Nova Finalidade de Locação"
              icon="pi pi-check"
              class="p-button-success"
              @click="adicionar"
            ></Button>
          </div>

          <div class="p-md-12" v-else>
            <div class="p-grid">
              <div class="p-md-6">
                <Button
                  label="Alterar"
                  icon="pi pi-check"
                  class="p-button-success"
                  @click="alterar"
                ></Button>
              </div>
              <div class="p-md-6">
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  class="p-button-warning"
                  @click="cancelarAlteracao"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>

export default {

  data() {

    return {

      //Controle de apresentação
      cadastro: true,
      loading: true,

      // Campos do Formulário
      nome: "",
      possui_outro: false,
      observacoes: "",

      // Dados cadastrados
      finalidades: [],

      // Dado selecionado
      finalidade: null,

    };

  },

  mounted() {

    const self = this;

    this.$api.get('/locacoes-finalidades').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        self.finalidades = dados.data;
        self.loading = false;

      }
      
    });

    this.carregarObjetoRota();
  },

  watch: {

    $route() {
      if(this.$route.name == "locacao-finalidade" || this.$route.name == "locacoes-finalidades")
        this.carregarObjetoRota();
    },

    finalidade: function (val) {
      
      if(val == null) {
        this.limparFormulario();
      } else {

        // Altera tela para modo de edição
        this.cadastro = false;
        
        //Popula a tela de alteração
        this.nome = val.nome;
        this.possui_outro = val.possui_outro;
        //obervacoes

      }

    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {

      const self = this;

      if(typeof this.$route.params.id != "string" || this.$route.params.id == "")
        return;

      var interval = setInterval(() => {
        if(!self.loading) {
          clearInterval(interval);

          var _finalidade = self.finalidades.filter((obj) => obj.id == self.$route.params.id);

          if(_finalidade.length > 0) {
            self.finalidade = _finalidade[0];
            return;
          }

          self.$api.get('/locacoes-finalidades/' + self.$route.params.id).then(function (response) {

            const dados = response.data;

            if(dados.success) {

              var finalidade = dados.data;

              self.finalidade = finalidade;

            } else {
              alert(dados.message);
              self.$router.push({ path: `/locacoes-finalidades` });
            }
            
          });

        }
      }, 500);

    },

    limparFormulario() {

      //Limpa as variáveis
      this.nome = "";
      this.possui_outro = false;
      this.observacoes = "";      

      // Altera tela para modo de edição
      this.cadastro = true;

    },

    // // // AÇÕES DO USUÁRIO // // //

    cancelarAlteracao() {

      this.finalidade = null;

      this.$router.push({ path: `/locacoes-finalidades` });

    },

    criar_alterar(criar = true) {

      const self = this;

      var dados = {
        'nome' : this.nome,
        'possui_outro' : this.possui_outro,
      };

      if(!criar) {
        dados['_method'] = 'PUT';
      }

      this.$api.post('/locacoes-finalidades' + ((!criar)?'/'+this.$route.params.id:''), dados).then(function (response) {

          if(response.data.success) {

            var v = response.data.data;

            if(criar) {

              self.finalidades.unshift(v);

            } else {

              for(const k of Object.keys(self.finalidades)) {
                if(self.finalidades[k].id == v.id) {
                  self.finalidades[k] = v;
                  break;
                }
              }

            }

            self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            self.cancelarAlteracao();

          } else {
            self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
          }

      }).catch(function (erro) {

        if(typeof erro.response != "undefined")
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

      });

    },

    alterar() {
      this.criar_alterar(false);
    },

    adicionar() {
      this.criar_alterar();
    },

    excluir(event, obj) {

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          this.$api.post('/locacoes-finalidades/' + obj.id, {
            '_method' : 'DELETE'
          }).then(function (response) {

            if(response.data.success) {

              for(const k of Object.keys(self.finalidades)) {
                if(self.finalidades[k].id == obj.id) {
                  self.finalidades.splice(k, 1);
                  break;
                }
              }

              self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            } else {
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
            }

          }).catch(function (erro) {

            if(typeof erro.response != "undefined")
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

          });

        }
      });
      
    },

  },

};
</script>

<style scoped>
</style>